import styled from "styled-components";

const StyledWrapper = styled.div`
  position: fixed;
  right: 1rem;
  top: 1rem;
  display: none;
  flex-direction: column;
  border-radius: 3px;
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  height: 36px;
  width: 36px;
  background-color: #5885f8;
  cursor: pointer;
  z-index: 3;
  @media screen and (max-width: 1140px) {
    display: flex;
  }
  > div {
    position: absolute;
    height: 2px;
    border-radius: 10px;
    width: 21px;
    background-color: var(--text-color);
    transition: 0.2s linear;
    &:first-child {
      top: 10px;
      left: 8px;
      transform: ${(props) => (props.active ? "rotate(45deg) translate3d(5px, 5px, 0)" : "none")};
    }
    &:nth-child(2) {
      top: 17px;
      left: 8px;
      transform: ${(props) => (props.active ? "rotate(-45deg) translate3d(0, 0, 0)" : "none")};
    }
    &:nth-child(3) {
      top: 24px;
      left: 8px;
      transform: ${(props) => (props.active ? "scaleX(0)" : "none")};
    }
  }
`;

const MobileMenuButton = ({ menuDisplay, toggle }) => {
  return (
    <StyledWrapper active={menuDisplay} onClick={() => toggle(!menuDisplay)}>
      <div />
      <div />
      <div />
    </StyledWrapper>
  );
};

export default MobileMenuButton;
