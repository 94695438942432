import axios, { AxiosError } from "axios";

export enum ERROR_CODES {
  SESSION_EXPIRED = "1000",
  CANNOT_REFER_SELF = "1001",
  AFFILIATE_CODE_NOT_ACTIVE = "1002",
  AT_CODE_CAPACITY = "1003",
  BAD_FORM_VALUES = "1004",
  UNIQUE_CODE_CLASH = "1005",
}

interface ErrBody {
  message: string;
  error?: Error;
  errorData?: unknown;
  errorCode: ERROR_CODES;
}

export const errorHasErrorCode = (e: unknown): e is AxiosError<ErrBody> => {
  return axios.isAxiosError(e) && typeof e.response?.data?.errorCode === "string";
};

export const isErrorCode = (e: unknown, code: ERROR_CODES | ERROR_CODES[]): boolean => {
  if (!errorHasErrorCode(e)) {
    return false;
  }

  const respErrorCode = e.response?.data?.errorCode;
  if (!respErrorCode) {
    return false;
  }

  const toMatch = Array.isArray(code) ? code : [code];

  return toMatch.includes(respErrorCode);
};
