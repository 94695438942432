import { proxy, subscribe } from "valtio";
import { useProxy } from "valtio/react/utils";

const initTokenStore = () => {
  if (typeof window !== "undefined") {
    window.addEventListener("storage", (event) => {
      onStorageEvent(event.key, event.oldValue, event.newValue);
    });

    return {
      token: JSON.parse(localStorage.getItem("auth-token")) || null,
      securityToken: JSON.parse(localStorage.getItem("redactSecurityToken")) || null,
      initialized: true,
    };
  }

  return {
    token: null,
    securityToken: null,
    initialized: false,
  };
};

export const tokenStore = proxy(initTokenStore());

subscribe(tokenStore, () => {
  localStorage.setItem("auth-token", JSON.stringify(tokenStore.token));
  localStorage.setItem("redactSecurityToken", JSON.stringify(tokenStore.securityToken));
});

export const useTokenStore = () => useProxy(tokenStore);

export const hydrateTokenStore = () => {
  if (typeof window !== "undefined") {
    if (!tokenStore.token) {
      tokenStore.token = JSON.parse(localStorage.getItem("auth-token")) || null;
    }
    if (!tokenStore.securityToken) {
      tokenStore.securityToken = JSON.parse(localStorage.getItem("redactSecurityToken") || null);
    }
  }
};

export const onStorageEvent = (key, oldValue, newValue) => {
  if (typeof window !== "undefined") {
    if (key === "auth-token") {
      if (JSON.parse(oldValue) !== tokenStore.token) {
        console.warn("Storage event values not equal", {
          oldValue,
          newValue,
          currentValue: tokenStore.token,
        });
      }
      tokenStore.token = JSON.parse(newValue);
    }
    if (key === "redactSecurityToken") {
      if (JSON.parse(oldValue) !== tokenStore.securityToken) {
        console.warn("Storage event values not equal", {
          oldValue,
          newValue,
          currentValue: tokenStore.securityToken,
        });
      }
      tokenStore.securityToken = JSON.parse(newValue);
    }
  }
};
