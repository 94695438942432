export const GTAG_ID = "G-N7MW7F3QBP";

function custom_event(name: string, data?: object) {
  let gtag = (window as any)?.gtag;
  if (!gtag) {
    setTimeout(() => {
      let gtag = (window as any)?.gtag;
      if (!gtag) {
        console.warn("Gtag wasn't defined even on retry");
        return;
      }
      gtag("event", name, data);
    }, 250);
    return;
  }
  gtag("event", name, data);
  // let dataLayer = (window as any).dataLayer;
  // if (!dataLayer) {
  //   console.warn("window.dataLayer not available");
  //   return;
  // }
  // dataLayer.push({
  //   eventData: undefined,
  // });
  // dataLayer.push({
  //   event: name,
  //   eventData: data || {},
  // });
}

export function gtag_set_user_id(user_id: string | null) {
  let gtag = (window as any)?.gtag;
  if (gtag) {
    console.log("G: Set user id");
    gtag("set", {
      user_id,
    });
  }
}

export function gtag_report_download() {
  console.log("Download");

  custom_event("app_download");
}

export function gtag_report_purchase(method: "stripe" | "paypal", value: number) {
  console.log("Purchase");
  // TODO: Remove this is server-sent purchase events pan out.
  custom_event("old_client_purchase", {
    value: value,
    currency: "USD",
    method,
  });
}
