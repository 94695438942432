export function getRewardfulReferralId(): string | undefined {
  if (typeof window !== "undefined") {
    const rewardfulGlobal = (window as any).Rewardful;
    if (rewardfulGlobal && rewardfulGlobal.referral) {
      return rewardfulGlobal.referral as string;
    }
  }
}

export interface ReferralData {
  referralId: string;
  couponId?: string;
}

function getRewardfulCoupon(rewardfulGlobal: any): string | undefined {
  if (typeof rewardfulGlobal.coupon?.id === "string") {
    return rewardfulGlobal.coupon.id;
  }
  if (typeof rewardfulGlobal.coupon === "string") {
    return rewardfulGlobal.coupon;
  }
  return undefined;
}

export function getRewardfulReferralData(): ReferralData | undefined {
  if (typeof window !== "undefined") {
    const rewardfulGlobal = (window as any).Rewardful;
    if (
      rewardfulGlobal &&
      rewardfulGlobal.referral &&
      typeof rewardfulGlobal.referral === "string"
    ) {
      const referralId = rewardfulGlobal.referral as string;
      const couponId = getRewardfulCoupon(rewardfulGlobal);
      return {
        referralId,
        couponId,
      };
    }
  }
  return undefined;
}
