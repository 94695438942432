export const regionSpecificData = {
    timezoneNames: [
        'Europe/Kaliningrad',
        'Europe/Moscow',
        'Europe/Simferopol',
        'Europe/Samara',
        'Europe/Istanbul',
        'Asia/Yekaterinburg',
        'Asia/Omsk',
        'Asia/Krasnoyarsk',
        'Asia/Irkutsk',
        'Asia/Yakutsk',
        'Asia/Vladivostok',
        'Asia/Magadan',
        'Asia/Kamchatka',
    ],
    languageCodes: [
        'ru-RU',
        'en-RU',
        'tr-TR',
        'en-TR',
    ],
}
