import MobileMenuButton from "components/nav/mobileMenuButton";
import MobileMenuPanel from "components/nav/mobileMenuPanel";
import { useState } from "react";

const MobileMenuWrapper = () => {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <>
      <MobileMenuPanel menuDisplay={showMenu} toggle={setShowMenu} />
      <MobileMenuButton menuDisplay={showMenu} toggle={setShowMenu} />
    </>
  );
};

export default MobileMenuWrapper;
