import Link from "next/link";
import styled, { keyframes } from "styled-components";

const animationShine = keyframes`
100% {
    transform: rotateZ(60deg) translate(1em, -9em);
  }
`;

const StyledButton = styled.button<{ "data-primary"?: boolean; "data-buddy"?: string }>`
  display: inline-flex;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  transition: 0.2s ease;
  border: none;

  background: var(--background-color, var(--raised-background-color));
  color: var(--text-color, #dedede);

  background: ${(props) => props["data-primary"] && "var(--primary-color, #7551cb)"};
  background: ${(props) =>
    props["data-buddy"] && "var(--buddy-color, var(--raised-background-color-three))"};

  box-shadow:
    0px 0px 0px 1px var(--shadow-color, #5630b2),
    0 4px 0px 1px var(--shadow-color, #5630b2);

  text-decoration: none;

  overflow: hidden;
  position: relative;

  &:hover {
    text-decoration: none;
    transform: translate(0, -2px);
    box-shadow:
      0px 0px 0px 1px
        ${(props) =>
          props["data-primary"]
            ? "#5630b2"
            : props["data-buddy"]
              ? "#7551cb"
              : "var(--text-color)"},
      0 6px 0px 1px
        ${(props) =>
          props["data-primary"]
            ? "#5630b2"
            : props["data-buddy"]
              ? "#7551cb"
              : "var(--text-color)"};
  }
  &:active {
    box-shadow:
      0px 0px 0px 1px
        ${(props) =>
          props["data-primary"]
            ? "#5630b2"
            : props["data-buddy"]
              ? "#7551cb"
              : "var(--text-color)"},
      0px 0px 0px 1px
        ${(props) =>
          props["data-primary"]
            ? "#5630b2"
            : props["data-buddy"]
              ? "#7551cb"
              : "var(--text-color)"};
    transform: translate(0, 4px);
    text-decoration: none;
  }
  &:after {
    content: "";
    position: absolute;
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
    background: linear-gradient(
      to bottom,
      rgba(117, 81, 203, 0),
      rgba(164, 140, 221, 0.3) 50%,
      rgba(117, 81, 203, 0)
    );
    transform: rotateZ(60deg) translate(-5em, 7.5em);
  }
  &:hover::after,
  &:focus::after {
    animation: ${animationShine} 1s forwards;
  }
`;

const StyledWrapper = styled.div<{
  alignSelf: React.CSSProperties["alignSelf"];
  disabled?: boolean;
}>`
  position: relative;
  align-self: ${(props) => props.alignSelf || "flex-start"};
  transition: transform 0.2s ease;

  ${(props) =>
    props.disabled &&
    `
      opacity: 0.5;
      pointer-events: none;
    `}
`;

interface CommonLinkProps {
  "data-primary"?: boolean;
  "data-buddy"?: string;
}
const StyledLink = styled.a<CommonLinkProps>`
  display: inline-flex;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  transition: 0.2s ease;

  background: var(--background-color, var(--raised-background-color));
  color: var(--text-color, #dedede);

  background: ${(props) => props["data-primary"] && "var(--primary-color, #7551cb)"};
  background: ${(props) =>
    props["data-buddy"] && "var(--buddy-color, var(--raised-background-color-three))"};

  box-shadow:
    0px 0px 0px 1px var(--shadow-color, #5630b2),
    0 4px 0px 1px var(--shadow-color, #5630b2);

  text-decoration: none;

  position: relative;
  overflow: hidden;

  &:hover {
    text-decoration: none;
    transform: translate(0, -2px);
    box-shadow:
      0px 0px 0px 1px
        ${(props) =>
          props["data-primary"]
            ? "#5630b2"
            : props["data-buddy"]
              ? "#7551cb"
              : "var(--text-color)"},
      0 6px 0px 1px
        ${(props) =>
          props["data-primary"]
            ? "#5630b2"
            : props["data-buddy"]
              ? "#7551cb"
              : "var(--text-color)"};
  }

  &:active {
    box-shadow:
      0px 0px 0px 1px
        ${(props) =>
          props["data-primary"]
            ? "#5630b2"
            : props["data-buddy"]
              ? "#7551cb"
              : "var(--text-color)"},
      0px 0px 0px 1px
        ${(props) =>
          props["data-primary"]
            ? "#5630b2"
            : props["data-buddy"]
              ? "#7551cb"
              : "var(--text-color)"};
    transform: translate(0, 4px);
    text-decoration: none;
  }
  &:after {
    content: "";
    position: absolute;
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
    background: linear-gradient(
      to bottom,
      rgba(117, 81, 203, 0),
      rgba(164, 140, 221, 0.3) 50%,
      rgba(117, 81, 203, 0)
    );
    transform: rotateZ(60deg) translate(-5em, 7.5em);
  }
  &:hover::after,
  &:focus::after {
    animation: ${animationShine} 1s forwards;
  }
`;
const StyledOutLink = styled.a<CommonLinkProps>`
  display: inline-flex;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  transition: 0.2s ease;

  background: var(--background-color, var(--raised-background-color));
  color: var(--text-color, #dedede);

  background: ${(props) => props["data-primary"] && "var(--primary-color, #7551cb)"};
  background: ${(props) =>
    props["data-buddy"] && "var(--buddy-color, var(--raised-background-color-three))"};

  box-shadow:
    0px 0px 0px 1px
      ${(props) =>
        props["data-primary"] ? "#5630b2" : props["data-buddy"] ? "#7551cb" : "var(--text-color)"},
    0 4px 0px 1px
      ${(props) =>
        props["data-primary"] ? "#5630b2" : props["data-buddy"] ? "#7551cb" : "var(--text-color)"};

  text-decoration: none;
  overflow: hidden;
  position: relative;

  &:hover {
    text-decoration: none;
    transform: translate(0, -2px);
    box-shadow:
      0px 0px 0px 1px
        ${(props) =>
          props["data-primary"]
            ? "#5630b2"
            : props["data-buddy"]
              ? "#7551cb"
              : "var(--text-color)"},
      0 6px 0px 1px
        ${(props) =>
          props["data-primary"]
            ? "#5630b2"
            : props["data-buddy"]
              ? "#7551cb"
              : "var(--text-color)"};
  }

  &:active {
    box-shadow:
      0px 0px 0px 1px
        ${(props) =>
          props["data-primary"]
            ? "#5630b2"
            : props["data-buddy"]
              ? "#7551cb"
              : "var(--text-color)"},
      0px 0px 0px 1px
        ${(props) =>
          props["data-primary"]
            ? "#5630b2"
            : props["data-buddy"]
              ? "#7551cb"
              : "var(--text-color)"};
    transform: translate(0, 4px);
    text-decoration: none;
  }
  &:after {
    content: "";
    position: absolute;
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
    background: linear-gradient(
      to bottom,
      rgba(117, 81, 203, 0),
      rgba(164, 140, 221, 0.3) 50%,
      rgba(117, 81, 203, 0)
    );
    transform: rotateZ(60deg) translate(-5em, 7.5em);
  }
  &:hover::after,
  &:focus::after {
    animation: ${animationShine} 1s forwards;
  }
`;

export interface ButtonProps {
  alignSelf?: React.CSSProperties["alignSelf"];
  buttonBuddy?: string;
  buttonPrimary?: boolean;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  href?: string;
  to?: string;
  onClick?: () => void;
  trackClick?: boolean;
  type?: "button" | "submit" | "reset";
  wrapperStyle?: React.CSSProperties;
  dataAttr?: string;
}

const Button = ({
  alignSelf,
  buttonBuddy,
  buttonPrimary,
  children,
  className,
  disabled,
  href,
  onClick,
  to,
  trackClick = false,
  type,
  wrapperStyle,
  dataAttr,
  ...rest
}: ButtonProps) => {
  if (onClick && !href && !to) {
    return (
      <StyledWrapper style={wrapperStyle} alignSelf={alignSelf} disabled={disabled}>
        <StyledButton
          data-primary={buttonPrimary}
          className={className}
          onClick={() => onClick()}
          data-buddy={buttonBuddy}
          type={type}
          data-attr={dataAttr || ""}
          {...rest}
        >
          {children}
        </StyledButton>
      </StyledWrapper>
    );
  } else if (to) {
    return (
      <StyledWrapper style={wrapperStyle} alignSelf={alignSelf} disabled={disabled}>
        <Link href={to} passHref legacyBehavior>
          <StyledLink
            data-primary={buttonPrimary}
            className={className}
            data-buddy={buttonBuddy}
            onClick={onClick}
            data-attr={dataAttr || ""}
            {...rest}
          >
            {children}
          </StyledLink>
        </Link>
      </StyledWrapper>
    );
  } else if (href) {
    return (
      <StyledWrapper style={wrapperStyle} alignSelf={alignSelf} disabled={disabled}>
        <StyledOutLink
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          data-primary={buttonPrimary}
          className={className}
          data-buddy={buttonBuddy}
          data-attr={dataAttr || ""}
          {...rest}
          onClick={() => {
            if (trackClick) {
              // clickUrlEvent(href);
            }
            if (onClick) {
              onClick();
            }
          }}
        >
          {children}
        </StyledOutLink>
      </StyledWrapper>
    );
  }
  return (
    <StyledWrapper style={wrapperStyle} alignSelf={alignSelf} disabled={disabled}>
      <StyledButton {...rest} className={className} data-primary={buttonPrimary}>
        {children}
      </StyledButton>
    </StyledWrapper>
  );
};

export default Button;
