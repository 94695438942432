import { useSearchParams } from "next/navigation";
import { useCallback, useEffect } from "react";
import { API } from "../api";
import { ERROR_CODES } from "../error";
import { useQueryParamSession } from "./useQueryParamSession";

const affiliateIdKey = "affiliateCode";

const useAffiliate = () => {
  const searchParams = useSearchParams();
  const acquire = useCallback(async ({ affiliateCode }: { affiliateCode: string }) => {
    const res = await API.acquireAffiliateSessionToken(affiliateCode);
    return res.data.data.sid;
  }, []);

  useEffect(() => {
    const affiliateCode = searchParams.get("affiliateCode");
    if (!affiliateCode) {
      return;
    }
    const incrementTraffic = async () => {
      await API.incrementAffiliateTraffic(affiliateCode);
    };
    incrementTraffic();
  }, [searchParams]);

  useQueryParamSession({
    keys: [affiliateIdKey],
    requiredKeys: [affiliateIdKey],
    acquire,
    associate: API.associateAffiliateSessionToken,
    dontRetryOnError: [ERROR_CODES.CANNOT_REFER_SELF],
    lsKey: "aff",
  });
};

export default useAffiliate;
