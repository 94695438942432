import axios from "axios";
import { tokenStore } from "./hooks/useToken";

export const http = axios.create({
  baseURL:
    process.env.DBUX_SERVER_URL ||
    process.env.NEXT_PUBLIC_DBUX_SERVER_URL ||
    "https://brazil.redact.dev",
});

http.interceptors.request.use((config) => {
  console.log({ token: tokenStore.token });
  const token = tokenStore.token;
  if (token) {
    if (!config.headers) config.headers = {};
    config.headers.authorization = `Bearer ${token}`;
  }
  return config;
});

if (typeof window !== "undefined") {
  const storedToken = localStorage.getItem("auth-token");
  if (storedToken) {
    tokenStore.token = JSON.parse(storedToken);
  }
}
