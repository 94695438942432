import { useCallback } from "react";
import { API } from "../api";
import { useQueryParamSession } from "./useQueryParamSession";

const sourceKey = "a";
const campaignKey = "b";

const useUTM = () => {
  const acquire = useCallback(async ({ a, b }: { a: string; b: string }) => {
    const res = await API.acquireCampaignSessionToken({ source: a, campaign: b });
    return res.data.data.sid;
  }, []);

  useQueryParamSession({
    keys: [sourceKey, campaignKey],
    requiredKeys: [sourceKey],
    acquire,
    associate: API.associateCampaignSessionToken,
    lsKey: "cmp",
  });
};

export default useUTM;
